<template>
  <div class="stripe">
    <form ref="form" class="stripe-card">
      <spinner-small v-if="!paymentElements" class="d-block" :is-dark="true" />
      <!-- This is where the stripe payment element gets injected: -->
      <div ref="paymentElement" />
    </form>

    <v-btn
      v-if="!hideSaveButton"
      x-large
      data-cy="stripeSubmitButton"
      block
      class="mx-0 mt-10 | pay-with-stripe"
      color="info"
      :disabled="!isFormComplete || !paymentElements || saving"
      :loading="saving"
      @click="triggerSetupStripePayment"
    >
      {{ saveButtonText }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import type {
  SetupIntent,
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripePaymentElement,
} from '@stripe/stripe-js'
import Vue from 'vue'

import SpinnerSmall from '~/components/SpinnerSmall.vue'

export default Vue.extend({
  name: 'StripePaymentElement',
  components: { SpinnerSmall },
  props: {
    business: {
      type: Boolean,
      default: true,
    },
    cancelButton: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
    },
    saving: {
      type: Boolean,
    },
    country: String,
  },
  data() {
    return {
      complete: false,
      setupIntent: null as SetupIntent | null,
      isFormComplete: false,
      paymentElements: null as StripeElements | StripePaymentElement | null,
    }
  },
  watch: {
    setupIntent: {
      handler(value: SetupIntent) {
        // stripe.com/docs/payments/save-and-reuse?platform=web#add-the-payment-element-to-your-payment-page
        if (value.client_secret) {
          void this.loadElement(value.client_secret)
        }
      },
      deep: true,
    },
    isFormComplete: {
      handler(value: boolean) {
        this.$emit('updateStripeFormValidity', {
          value,
          setupIntent: this.setupIntent,
        })
      },
    },
    paymentElements: {
      handler() {
        this.$emit('updatePaymentElements', {
          stripeElements: this.paymentElements,
        })
      },
      deep: true,
    },
  },
  async created() {
    await this.getSetupIntent()
  },
  methods: {
    async loadElement(clientSecret: string): Promise<void> {
      const options = {
        locale: (this.$store.state as RootState).locale || 'en',
        clientSecret,
        // Fully customizable with appearance API.
        appearance: {
          theme: 'stripe',
        },
      }
      await this.$store.dispatch('setupStripe')
      const stripe = this.$store.app.$stripe as Stripe
      if (stripe) {
        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        const elements = stripe.elements(options as StripeElementsOptionsClientSecret)
        // Create and mount the Payment Element
        const paymentElement = elements.create('payment', {
          fields: { billingDetails: 'never' },
          paymentMethodOrder: ['card', 'sepa_debit'],
          wallets: {
            applePay: 'never',
            googlePay: 'never',
          },
        })
        paymentElement.on('change', event => {
          this.isFormComplete = event.complete
        })
        paymentElement.mount(this.$refs.paymentElement as HTMLElement)
        this.paymentElements = elements
      }
    },
    async triggerSetupStripePayment(): Promise<void> {
      this.$emit('startStripePaymentSetup', {
        setupIntent: this.setupIntent,
        stripeElements: this.paymentElements,
      })
      await this.getSetupIntent()
    },
    async getSetupIntent(): Promise<void> {
      try {
        const dispatchEvent = this.business ? 'business/getStripeSetupIntent' : 'billing/getStripeSetupIntent'
        const setupIntent: { setup_intent: SetupIntent } = (await this.$store.dispatch(dispatchEvent, {
          country: this.country,
        })) as {
          setup_intent: SetupIntent
        }
        this.setupIntent = setupIntent.setup_intent
        this.$emit('updateSetupIntent', setupIntent.setup_intent)
      } catch (error) {
        void this.$store.dispatch('setSnackbar', {
          message: { key: 'global.general_error' },
        })
        throw new Error('Wave could not get setupIntent in StripePaymentElement')
      }
    },
  },
})
</script>

<style lang="sass">
.stripe
  &-card
    padding: 20px
    border: 0
    border-radius: 3px
    background: var(--v-grey-fine-base)
</style>
