<template>
  <v-expansion-panel disabled>
    <v-expansion-panel-header>
      <v-container class="pa-0">
        <v-row>
          <v-col cols="3">
            <strong class="pr-4">
              {{ title }}
            </strong>
          </v-col>
          <v-col cols="9">
            {{ message }}
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-header>
  </v-expansion-panel>
</template>

<script lang="ts">
import Vue from 'vue'

interface Props {
  title: string
  message: string
}

export default Vue.extend<void, void, void, Props>({
  name: 'ExpansionPanelDisabled',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
})
</script>
