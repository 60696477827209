<template>
  <v-expansion-panel class="expansion-panel-payment-method" :disabled="loading" @change="toggleIcon()">
    <v-expansion-panel-header>
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="3">
            <spinner-small v-if="loading" class="d-block" :is-dark="true" />
            <strong v-else class="pr-4">
              {{ paymentPanelTitle }}
            </strong>
          </v-col>
          <v-col cols="9">
            <div class="d-flex justify-space-between">
              <div class="pr-4">
                {{ paymentPanelInfo1 }}
              </div>
              <div class="pr-4">
                {{ paymentPanelInfo2 }}
              </div>
              <div v-if="!defaultPaymentMethod && !loading" class="pr-2 | expansion-panel-payment-method__add">
                {{ $t('expansion_panel.payment_method.add_hint') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <template #actions>
        <div v-if="!loading" class="v-ep__svg-wrapper">
          <v-icon :color="!defaultPaymentMethod ? 'primary' : 'txext'" :class="{ show: isActive }">
            $vuetify.icons.clear
          </v-icon>
          <v-icon :color="!defaultPaymentMethod ? 'primary' : 'txext'" :class="{ show: !isActive }">
            $vuetify.icons.pencil
          </v-icon>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="d-flex flex-column justify-space-between">
        <p>
          <strong>{{ $t('global.update_payment_method.hint') }}</strong>
        </p>
        <stripe-payment-element
          :key="company.billing.country"
          :save-button-text="saveButtonText"
          :saving="saving"
          :country="company.billing.country"
          @startStripePaymentSetup="
            $emit('updatePaymentMethod', {
              stripeElements: $event.stripeElements,
            })
          "
        />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import Vue from 'vue'
import type { TranslateResult } from 'vue-i18n'
import { mapState } from 'vuex'

import SpinnerSmall from '~/components/SpinnerSmall.vue'
import StripePaymentElement from '~/components/stripe/StripePaymentElement.vue'

interface Props {
  loading: boolean
  saving: boolean
  saveButtonText: string
  isActive: boolean
}

interface Computed {
  defaultPaymentMethod: DefaultPaymentMethod | null
  paymentPanelInfo1: string
  paymentPanelInfo2: string
  paymentPanelTitle: TranslateResult
}

interface Methods {
  toggleIcon: () => void
}

export default Vue.extend<void, Methods, Computed, Props>({
  name: 'ExpansionPanelPaymentMethod',
  components: { StripePaymentElement, SpinnerSmall },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPanelOpen: false,
    }
  },
  computed: {
    ...mapState('business', ['company', 'defaultPaymentMethod']),
    paymentPanelInfo1(): string {
      if (this.saving || !this.defaultPaymentMethod) return ''
      else if (this.defaultPaymentMethod.type === 'card') {
        const defaultPaymentMethod = this.defaultPaymentMethod.paymentInformation
        const cardBrand = defaultPaymentMethod?.brand.toUpperCase() ?? ''
        const endingInString = this.$t('business.payment_method.ending_in') as string
        const lastDigits = defaultPaymentMethod.last4 || 0
        return `${cardBrand} ${endingInString} ${lastDigits}`
      } else if (this.defaultPaymentMethod.type === 'sepa') {
        return `${this.$t('expansion_panel.payment_method.sepa_last_digits') as string} ${
          this.defaultPaymentMethod.paymentInformation.last4
        }`
      }
      return ''
    },
    paymentPanelInfo2(): string {
      if (this.saving || !this.defaultPaymentMethod) return ''
      else if (this.defaultPaymentMethod.type === 'card') {
        const defaultPaymentMethod = this.defaultPaymentMethod.paymentInformation
        const expiresOnString = this.$t('business.payment_method.expiry') as string
        const formattedExpiryMonth = (defaultPaymentMethod.exp_month ?? 0).toString().padStart(2, '0')
        const formattedExpiryYear = (defaultPaymentMethod.exp_year ?? 0).toString().substring(2, 4)
        return `${expiresOnString} ${formattedExpiryMonth}/${formattedExpiryYear}`
      }
      return ''
    },
    paymentPanelTitle() {
      if (!this.defaultPaymentMethod) return this.$t('global.payment_method')
      if (this.defaultPaymentMethod?.type === 'sepa') return this.$t('expansion_panel.payment_method.sepa_title')
      return this.$t('business.payment_method.credit_card')
    },
  },
  methods: {
    toggleIcon() {
      this.$emit('billingPanelOpened', { source: 'paymentMethod' })
    },
  },
})
</script>

<style scoped lang="sass">
.expansion-panel-payment-method
  &__add
    color: $c-primary
  .v-ep__svg-wrapper
    width: 24px
    height: 24px
    .v-icon
      top: 0
      left: 0
</style>
