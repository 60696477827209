import { render, staticRenderFns } from "./ExpansionPanelPaymentMethod.vue?vue&type=template&id=39a51094&scoped=true"
import script from "./ExpansionPanelPaymentMethod.vue?vue&type=script&lang=ts"
export * from "./ExpansionPanelPaymentMethod.vue?vue&type=script&lang=ts"
import style0 from "./ExpansionPanelPaymentMethod.vue?vue&type=style&index=0&id=39a51094&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a51094",
  null
  
)

export default component.exports